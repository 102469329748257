<template>

  <div class="logout rel-container">
    <v-container fill-height>
    <v-layout fill-height align-center justify-center>

      <v-card>

        <div class="blue darken text-xs-center py-2">
          <span class="headline white--text px-1">You have been logged out</span>
        </div>

        <v-card-text>
          <div style="text-align:center;">

            <p>We hope to see you again soon!</p>

            <v-btn large color="info" to="/">Return Home</v-btn>
            <br />
            <!-- <span class="grey--text text--lighten-1">See you later!</span> -->

          </div>
        </v-card-text>

      </v-card>

    </v-layout>
    </v-container>
  </div>

</template>


<script>
export default {
  name:'logout',
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  }
}
</script>

<style scoped>

.logout {
  background-color: #B03060;
}

</style>
